import React from "react";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import SecondaryBanner from "../../Components/SecondaryBanner";
import CardDownload from "../../Components/CardDownload";
import { Table } from "../../Components/Table";
const InterestRate = () => {
  useEffect(() => {
    document.title = "Interest Rates";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "Interest Rates"],
    bannerTitle: "Interest Rates",
  };
  const tableHeaerFD = [
    "Particulars",
    "Individual",
    "Individual (Remit)",
    "Institutional",
    "Institutional (Bidding)",
  ];
  const tableDataFD = [
    ["3 Months", "6.75%", "7.75%", "-", "-"],
    ["6 Months", "7.00%", "8.00%", "5.00%", "-"],
    ["9 Months", "7.25%", "8.25%", "5.25%", "-"],
    ["12 Months to Below 24 Months", "7.35%", "8.35%", "5.45%", "-"],
    ["24 Months and Above", "7.45%", "8.45%", "5.50%", "-"],
    //["36 Months and Above", "11.50%", "12.50%", "9.50%", "-"],
  ];
  const tableHeaderSaving = [
    "Saving Deposits",
    "Minimum Balance Rs.",
    "Interest Rate",
  ];
  const tableDataSaving = [
    ["Bal Bachat", "100.00", "4.50%"],
    ["Regular Saving", "200.00", "4.50%"],
    ["Payroll Savings", "0.00", "4.50%"],
    ["Couple Saving", "200.00", "4.50%"],
    ["Welfare Saving", "0.00", "4.50%"],
    ["Student Savings", "200.00", "4.50%"],
    ["Non-Profitable Org. Saving", "1,000.00", "4.50%"],
    ["Shareholders Savings", "200.00", "4.50%"],
    ["Staff Savings", "200.00", "4.50%"],
    ["Nari Bachat", "250.00", "4.75%"],
    ["Jestha Nagarik", "250.00", "4.75%"],
    ["Gurkhas Pension Saving", "5,000.00", "5.50%"],
    ["Special Savings", "5,000.00", "5.50%"],
    ["Remittance Saving", "200.00", "6.50%"],
    ["Corporate Flexible Saving", "1,000.00", "Up to 2.25%"],
  ];
  const tableHeaderLoan = ["Loans and Advances", "Interest Rate %"];
  const tableDataLoan = [
    ["Home Loan", "BR + Upto 7%"],
    ["Personal Loan(OD/Term)", "BR + Upto 7%"],
    ["Share Loan", "BR + Upto 7%"],
    ["Gold Loan", "BR + Upto 7%"],
    ["Business/Working Capital Loan", "BR + Upto 7%"],
    ["Agriculture/SME/Education Loan", "BR + Upto 7% "],
    ["Small Farm Individual Loan", "BR + Upto 7%"],
    ["Hire Purchase", "BR + Upto 7%"],
    ["Real Estate Loan", "BR + Upto 7%​"],
    ["Professional Loan/Bridge Gap Loan", "BR + Upto 7%​"],
    ["Loan Against FD", "Coupon rate + Up to 2 % or BR whichever is higher"],
    ["Consortium Loan", "As per Consortium Decision"],

    // ['Consortium Loan', 'As per Consortium Decision'],
  ];
  const tableHeaderLending = ["Deprive Sector Lending", "Interest Rate %"];
  const tableDataLending = [
    ["Indirect Lending (Wholesale or Institutional)", "Base Rate + Up to 2%"],
    ["Direct lending (All types)", "Base Rate + Up to 7%"],
    ["Base rate of Shrawan end, 2081", "9.90%"],
    // ["Third Quarter Average Base Rate", "12.71%"],
    ["Spread Rate (2081 Shrawan)", "4.58%"],
  ];
  const tableHeaderFixedInterest = [
    "Fixed Interest Rate Loan (for individual)",
    "Upto 5 Years",
    "5 to 10 Years",
    "Above 10 Years",
  ];
  const tableDataFixedInterest = [
    ["Home Loan", "13.00%", "13.50%", "14.00%"],
    ["Auto Loan/HP Loan", "13.00%", "13.50%", "-"],
    ["Other Retail Loan", "13.00%", "13.50%", "14.00%"],
  ];
  const cardData = {
    year2078: [
      {
        id: 1,
        title: "Baisakh 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate  Baisakh 2078.pdf",
        year: "2078/79",
      },
      {
        id: 2,
        title: "Shrawan 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Shrawan 2078_3x25cc.pdf",
        year: "2078/79",
      },
      {
        id: 3,
        title: "Bhadra 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Bhadra Interest Rate 3x25cc,2078.pdf",
        year: "2078/79",
      },
      {
        id: 4,
        title: "Ashoj 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest rate for the month of Ashoj 2078.pdf",
        year: "2078/79",
      },
      {
        id: 5,
        title: "kartik 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/kartik ,078.pdf",
        year: "2078/79",
      },
      {
        id: 6,
        title: "Mangsir 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Mamngsir  Interest Rate 3x20cc,078.pdf",
        year: "2078/79",
      },
      {
        id: 7,
        title: "Poush 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Poush 3x20cc,078.pdf",
        year: "2078/79",
      },
      {
        id: 8,
        title: "Magh 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Magh 2078 3x20cc.pdf",
        year: "2078/79",
      },
      {
        id: 9,
        title: "Falgun 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Falgun,078 3x20cc 078.pdf",
        year: "2078/79",
      },
      {
        id: 10,
        title: "Chaitra 2078",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Chaitra 078 3x28cc,78.pdf",
        year: "2078/79",
      },
    ],
    year2079: [
      {
        id: 1,
        title: "Jeshtha 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/JESTHA 079.pdf",
        year: "2078/79",
      },
      {
        id: 2,
        title: "Ashad 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Ashad 079 Int. Rate 3x28cc.pdf",
        year: "2078/79",
      },
      {
        id: 3,
        title: "Shrawan 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Shrawan 3x24cc INT 079.pdf",
        year: "2078/79",
      },
      {
        id: 4,
        title: "Bhadra 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Bhadra Rate 3x24cc 079.pdf",
        year: "2078/79",
      },
      {
        id: 5,
        title: "Ashwin 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Ashwin interest rate 2079.pdf",
        year: "2078/79",
      },
      {
        id: 6,
        title: "Kartik 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Kartik 2079.pdf",
        year: "2078/79",
      },
      {
        id: 7,
        title: "Mangsir 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest rate for the month of Magh 2079 3x24cc.pdf",
        year: "2078/79",
      },
      {
        id: 8,
        title: "Poush 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest rate  6x24 cc.pdf",
        year: "2078/79",
      },
      {
        id: 9,
        title: "Magh 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/month of Poush 2079.pdf",
        year: "2078/79",
      },
      {
        id: 10,
        title: "Falgun 2079",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Falgun Interest Rate.pdf",
        year: "2078/79",
      },
      // {
      //   id: 11,
      //   title: 'Chaitra 2079',
      //   link:
      //     'https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Gurkhas finance(2080-1).pdf',
      //   year: '2078/79',
      // },
    ],
    year2080: [
      {
        id: 1,
        title: "Baishakh  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Gurkhas finance(2080-1).pdf",
        year: "2079/80",
      },
      {
        id: 2,
        title: "Jestha  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Gurkhas finance-15th_May-2023.pdf",
        year: "2079/80",
      },
      {
        id: 3,
        title: "Ashadh  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Gurkhas finance-16th_June-2023.pdf",
        year: "2079/80",
      },
      {
        id: 4,
        title: "Shrawan  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Shrawan_Interest_Rate_2080.pdf",
        year: "2079/80",
      },
      {
        id: 5,
        title: "Bhadra  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Bhadra_Interest_Rate_2080.pdf",
        year: "2079/80",
      },
      {
        id: 6,
        title: "Ashoj  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Ashoj_Interest_Rate_2080.pdf",
        year: "2079/80",
      },
      {
        id: 6,
        title: "Kartik  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Kartik_Interest_Rate_2080.pdf",
        year: "2079/80",
      },
      {
        id: 7,
        title: "Mangsir  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Mangsir_Interest_Rate_2080.pdf",
        year: "2079/80",
      },
      {
        id: 8,
        title: "Poush  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Poush_Interest_Rate_2080.pdf",
        year: "2079/80",
      },
      {
        id: 9,
        title: "Magh  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Magh_Interest_Rate_2080.pdf",
        year: "2079/80",
      },
      {
        id: 10,
        title: "Falgun  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Falgun_Interest_Rate_2080.pdf",
        year: "2079/80",
      },
      {
        id: 11,
        title: "Chaitra  2080",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Chaitra_Interest_Rate_2080.pdf",
        year: "2079/80",
      },
    ],
    year2081: [
      {
        id: 1,
        title: "Baishakh  2081",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Gurkhas finance(2081-1).pdf",
        year: "2080/81",
      },
      {
        id: 2,
        title: "Jestha  2081",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Jeshtha 2081.pdf",
        year: "2080/81",
      },
      {
        id: 3,
        title: "Ashadh  2081",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Ashadh 2081.pdf",
        year: "2080/81",
      },
      {
        id: 4,
        title: "Shrawan  2081",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Shrawan 2081.pdf",
        year: "2080/81",
      },
      {
        id: 4,
        title: "Bhadra  2081",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Bhadra 2081.jpg",
        year: "2080/81",
      },
      {
        id: 5,
        title: "Ashwin  2081",
        link: "https://gurkhasfinance.com.np/assets/interest rate/Interest Rate-Ashwin 2081.pdf",
        year: "2080/81",
      },
    ],
    
  };
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-table">
        <div className="container">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Interest Rate with Effective from 2081.06.01(17th September 2024)
              </Accordion.Header>
              <Accordion.Body>
                <div
                  className="text-container"
                  style={{ padding: "10px 68px" }}
                >
                  <h1>Fixed Deposits</h1>
                </div>
                <div className="tables">
                  <Table tableHeader={tableHeaerFD} tableData={tableDataFD} />
                </div>
                <div
                  className="text-container"
                  style={{ padding: "10px 68px" }}
                >
                  <h1>Saving Deposits</h1>
                </div>
                <div className="tables">
                  <Table
                    tableHeader={tableHeaderSaving}
                    tableData={tableDataSaving}
                  />
                </div>
                <div
                  className="text-container"
                  style={{ padding: "10px 68px" }}
                >
                  <h1>Loans and Advances</h1>
                </div>
                <div className="tables">
                  <Table
                    tableHeader={tableHeaderLoan}
                    tableData={tableDataLoan}
                  />
                </div>
                <div className="tables">
                  <Table
                    tableHeader={tableHeaderLending}
                    tableData={tableDataLending}
                  />
                </div>
                <div className="tables">
                  <Table
                    tableHeader={tableHeaderFixedInterest}
                    tableData={tableDataFixedInterest}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Interest Rate of 2081</Accordion.Header>
              <Accordion.Body>
                {cardData &&
                  cardData.year2081.reverse().map((card) => (
                    <div className="card-row">
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Interest Rate of 2080</Accordion.Header>
              <Accordion.Body>
                {cardData &&
                  cardData.year2080.reverse().map((card) => (
                    <div className="card-row">
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Interest Rate of 2079</Accordion.Header>
              <Accordion.Body>
                {cardData &&
                  cardData.year2079.reverse().map((card) => (
                    <div className="card-row">
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Interest Rate of 2078</Accordion.Header>
              <Accordion.Body>
                {cardData &&
                  cardData.year2078.map((card) => (
                    <div className="card-row">
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </main>
  );
};

export default InterestRate;
